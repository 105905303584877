import Vue from 'vue'
import { productsListNew } from '@/queries/products.gql'

import { formatProductCards } from '@/utils'
export const state = () => ({
  allProducts: {},
  comparableProductList: [],
})

export const mutations = {
  removeProductFromComparableList(state, payload) {
    state.comparableProductList = state.comparableProductList.filter(
      (item) => item.id !== payload.id,
    )
  },
  addProductToComparableList(state, payload) {
    state.comparableProductList = [...state.comparableProductList, payload]
  },
  resetComparableProductListState(state) {
    state.comparableProductList = []
  },
  saveComparableProductsToLocalStorage(state) {
    localStorage.setItem(
      `${this.$config.domainCode}-comparable-products`,
      JSON.stringify(state.comparableProductList),
    )
  },
  loadComparableProductsFromLocalStorage(state) {
    const storedProducts = localStorage.getItem(
      `${this.$config.domainCode}-comparable-products`,
    )
    if (storedProducts) {
      state.comparableProductList = JSON.parse(storedProducts)
    }
  },
  addProductsToSection(state, payload) {
    if (payload.list?.length >= 0) {
      const formattedObject = {
        total: payload?.total || 0,
        list: payload.list,
      }
      Vue.set(state.allProducts, payload.id, formattedObject)
    }
  },
}

export const actions = {
  async fetchById({ commit, state }, { id, first = 6 }) {
    const _id = parseInt(id) || 0
    if (_id < 0 || state.allProducts?.[`${id}`]?.total <= 0) return

    const {
      data: { productsList: products },
    } = await this.app.$gqlQuery({
      query: productsListNew,
      fetchPolicy: 'no-cache',
      variables: {
        languageCode: this.$i18n.locale,
        domainId: this.$config.domainId,
        first,
        excludeTags: this.$config.tagsToExclude,
        page: 1,
        pageId: _id,
        orderByTitle: 'ASC',
      },
    })

    if (!Array.isArray(products?.data)) return

    const shouldFormatAsProduct = this.$config.isTelematics

    commit('addProductsToSection', {
      id: _id,
      list: formatProductCards(products.data, shouldFormatAsProduct),
      total: products?.paginatorInfo?.total || 0,
    })
  },
  resetComparableProductList({ commit }) {
    commit('resetComparableProductListState')
  },
  toggleComparableProduct({ commit, state }, payload) {
    const isProductInList = state.comparableProductList.some(
      (item) => item.id === payload.id,
    )

    isProductInList
      ? commit('removeProductFromComparableList', payload)
      : commit('addProductToComparableList', payload)

    commit('saveComparableProductsToLocalStorage')
  },
  loadComparableProducts({ commit }) {
    commit('loadComparableProductsFromLocalStorage')
  },
}
